import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainNavComponent } from './page/navigation/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MainNavContentComponent } from './component/navigation/main-nav-content/main-nav-content.component';
import {
  HttpClientModule,
  HttpClient,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemComponent } from './component/tools/menu/menu-item/menu-item.component';
import { SubMenuItemComponent } from './component/tools/menu/menu-item/sub-menu-item/sub-menu-item.component';
import { SidenavContentComponent } from './component/navigation/sidenav-content/sidenav-content.component';
import { TreeMenuItemComponent } from './component/tools/menu/tree-menu-item/tree-menu-item.component';
import { FooterComponent } from './page/navigation/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './page/landings/home/home.component';
import { UsecasesPanelComponent } from './component/landings/usecases-panel/usecases-panel.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PanelListComponent } from './component/tools/panel/panel-list/panel-list.component';
import { PanelListItemComponent } from './component/tools/panel/panel-list/panel-list-item/panel-list-item.component';
import { PanelCardSmallComponent } from './component/tools/panel/card/panel-card-small/panel-card-small.component';
import { PanelCardLargeComponent } from './component/tools/panel/card/panel-card-large/panel-card-large.component';
import { StepsPanelComponent } from './component/landings/steps-panel/steps-panel.component';
import { StepComponent } from './component/landings/steps-panel/step/step.component';
import { StepFooterComponent } from './component/landings/steps-panel/step-footer/step-footer.component';
import { ToolsPanelComponent } from './component/landings/tools-panel/tools-panel.component';
import { ToolComponent } from './component/landings/tools-panel/tool/tool.component';
import { ColoredTextDirective } from './directive/colored-text.directive';
import { RemoteComponent } from './page/landings/remote/remote.component';
import { SimpleTableComponent } from './component/tools/table/simple-table/simple-table.component';
import { CirclePanelComponent } from './component/tools/panel/circle-panel/circle-panel.component';
import { CircleItemComponent } from './component/tools/panel/circle-panel/circle-item/circle-item.component';
import { FlexComponent } from './page/landings/flex/flex.component';
import { ListClientComponent } from './component/landings/list-client/list-client.component';
import { ContactComponent } from './page/landings/contact/contact.component';
import { SmartComponent } from './page/landings/smart/smart.component';
import { MoffiUiKitModule } from '@moffi/moffi-ui-kit';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { ContactIdeaComponent } from './page/landings/contact-idea/contact-idea.component';
import { ContactJoinTeamComponent } from './page/landings/contact-join-team/contact-join-team.component';
import { OfferComponent } from './page/landings/offer/offer.component';
import { OfferItemComponent } from './component/landings/offers-panel/offer-item/offer-item.component';
import { OffersPanelComponent } from './component/landings/offers-panel/offers-panel.component';
import { ExpansivePanelComponent } from './component/tools/panel/expansive-panel/expansive-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MarketplaceComponent } from './page/landings/marketplace/marketplace.component';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { MessageEnvoyeComponent } from './page/landings/message-envoye/message-envoye.component';
import { SnackbarComponent } from './component/tools/snackbar/snackbar.component';
import { MarketplaceItemComponent } from './component/landings/marketplace/marketplace-item/marketplace-item.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { PartnerComponent } from './page/landings/partner/partner.component';
import { GlossaryComponent } from './page/landings/glossary/glossary.component';
import { DefinitionComponent } from './page/landings/definition/definition.component';
import { GlossaryItemComponent } from './component/landings/glossary/glossary-item/glossary-item.component';
import { ArticleCardComponent } from './component/landings/glossary/article-card/article-card.component';
import { HighlightPipe } from './pipe/highlight.pipe';
import { HighlightAndMarginPipe } from './pipe/higlinghtAndMargin.pipe';
import { WaldoComponent } from './page/landings/waldo/waldo.component';

// Fonction pour charger les fichiers JSON de traduction
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    MainNavContentComponent,
    MenuItemComponent,
    SubMenuItemComponent,
    SidenavContentComponent,
    TreeMenuItemComponent,
    FooterComponent,
    HomeComponent,
    UsecasesPanelComponent,
    PanelListComponent,
    PanelListItemComponent,
    PanelCardSmallComponent,
    PanelCardLargeComponent,
    StepsPanelComponent,
    StepComponent,
    StepFooterComponent,
    ToolsPanelComponent,
    ToolComponent,
    RemoteComponent,
    SimpleTableComponent,
    CirclePanelComponent,
    CircleItemComponent,
    ContactComponent,
    SmartComponent,
    FlexComponent,
    ListClientComponent,
    ContactIdeaComponent,
    ContactJoinTeamComponent,
    OfferComponent,
    OfferItemComponent,
    OffersPanelComponent,
    ExpansivePanelComponent,
    MarketplaceComponent,
    MessageEnvoyeComponent,
    SnackbarComponent,
    MarketplaceItemComponent,
    PartnerComponent,
    GlossaryComponent,
    DefinitionComponent,
    GlossaryItemComponent,
    ArticleCardComponent,
    HighlightPipe,
    HighlightAndMarginPipe,
    WaldoComponent
  ],
  imports: [
    MoffiUiKitModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTabsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ColoredTextDirective,
    MatSnackBarModule,
    MatCheckboxModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr-FR',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lf023YUAAAAAFnCbHF3vM_XQE0O0CfxOjkmtPLk',
      } as RecaptchaSettings,
    },
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {},
    },
    provideHttpClient(withFetch()),
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
