import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterRenderPhase,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  afterNextRender,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api/api.service';
import { PartnersService } from 'src/app/service/partners/partners.service';
import { NavigationService } from 'src/app/service/navigation/navigation.service';
import { ScrollEventService } from 'src/app/service/scroll-event/scroll-event.service';
import { SeoService } from 'src/app/service/seo/seo.service';
import { ScrollAnimation } from 'src/app/utils/scroll-animations';
import { DefinitionsService } from 'src/app/service/definitions/definitions.service';

declare let gtag: Function;

@Component({
  selector: 'app-abstract-page',
  template: '',
  styleUrl: './abstract.page.component.scss',
})
export abstract class AbstractPageComponent {
  languages: string[] = ['fr-FR', 'fr-CA', 'en-GB'];

  // Liste des clients Moffi
  clients: string[] = [
    'ovh',
    'carrefour',
    'cyrillus',
    'la_poste',
    'veolia',
    'santander',
    'sante_vet',
    'claranet',
  ];

  // Retourne true si l'écran est en mode mobile
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe('(max-width: 1150px)')
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  protected currentLanguage: string;
  protected currentShortLanguage: string;
  protected languageChangeSubscription!: Subscription;
  protected scrollEventSubscription!: Subscription;

  protected isChildRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Constructeur
  constructor(
    protected el: ElementRef,
    protected renderer: Renderer2,
    protected activatedRoute: ActivatedRoute,
    protected apiService: ApiService,
    protected partnersService: PartnersService,
    protected definitionsService: DefinitionsService,
    private _snackBar: MatSnackBar,
    protected router: Router,
    protected breakpointObserver: BreakpointObserver,
    protected meta: Meta,
    protected title: Title,
    protected translateService: TranslateService,
    protected scrollEventService: ScrollEventService,
    protected navigationService: NavigationService,
    protected seoService: SeoService,
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Inject(DOCUMENT) protected document: Document
  ) {
    this.currentLanguage = translateService.currentLang;
    this.currentShortLanguage = this.getLangShortText(this.currentLanguage);

    // Écouter les changements de route pour mettre à jour currentLang
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        // Google Analytics
        if (isPlatformBrowser(this.platformId)) {
          if (event instanceof NavigationEnd) {
            gtag('config', 'G-LJSP8FMHG7', {
              page_path: event.urlAfterRedirects,
            });
          }
        }

        // Gestion des langues
        const langMatch = this.router.url.match(/^\/(fr-ca|fr|en)/);
        if (langMatch) {
          const lang = langMatch[1];
          this.currentLanguage = this.getLangFullText(lang);
          this.currentShortLanguage = lang;
          this.translateService.use(this.currentLanguage);
        } else {
          let userLang = 'fr-FR';
          if (isPlatformBrowser(this.platformId)) {
            try {
              userLang = localStorage.getItem('userLang') ?? 'fr-FR';
            } catch (error) {
              // Do nothing
            }
          }

          if (!userLang) {
            // Langue par défaut du navigateur
            let browserLanguage: string = 'fr-FR';
            if (isPlatformBrowser(this.platformId)) {
              browserLanguage = navigator.language;
            }

            if (this.languages.includes(browserLanguage)) {
              userLang = browserLanguage;
              // On récupère les 2 premiers caractères
            } else if (browserLanguage.length >= 2) {
              switch (browserLanguage.substring(0, 2).toLowerCase()) {
                case 'fr':
                  userLang = 'fr-FR';
                  break;
                case 'en':
                  userLang = 'en-GB';
                  break;
                default:
                  userLang = 'en-GB';
                  break;
              }
            } else {
              userLang = 'en-GB';
            }
          }
          this.currentLanguage = userLang;
          this.currentShortLanguage = this.getLangShortText(
            this.currentLanguage
          );
          this.translateService.use(userLang);
        }

        this.isChildRoute$.next(this.activatedRoute.firstChild !== null);
      });

      if (isPlatformBrowser(this.platformId)) {
        afterNextRender(
          () => {
            window.dispatchEvent(new Event('resize'));
          },
          { phase: AfterRenderPhase.Write }
        );
      }
  }

  ngOnInit() {
    this.setHeaderMetadatas();
    this.seoService.updateCanonicalLink();
    this.seoService.updateAlternateLinks();

    this.languageChangeSubscription =
      this.translateService.onLangChange.subscribe(() => {
        // Set les méta données
        this.seoService.updateLangAttribute();
        this.seoService.updateCanonicalLink();
        this.setHeaderMetadatas();
        this.updateCurrentLanguage();
      });

    // Intercepte les évènement de scroll sur le main content
    this.scrollEventSubscription =
      this.scrollEventService.scrollEvent.subscribe((scrollPosY) => {
        this.launchAnimations();
      });  
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.launchAnimations();
    }
  }

  private updateCurrentLanguage() {
    this.currentLanguage = this.translateService.currentLang;
    this.navigationService.updateCurrentUrl();
  }

  ngOnDestroy() {
    this.languageChangeSubscription?.unsubscribe();
    this.scrollEventSubscription?.unsubscribe();
  }

  /**
   * Retourne la traduction d'un texte à partir de sa clé
   * @param key
   * @returns
   */
  getTranslation(key: string): string {
    let translation: string = '';
    this.translateService.get(key).subscribe((value: string) => {
      translation = value;
    });
    return translation;
  }

  createAnimationHorizontalPanel(className: string) {
    this.createAnimationHorizontalPanelDelay(className, 0.7, 0.7, 2);
  }

  /**
   * Génère une animation spécifique aux panneaux horizontaux
   * @param className
   */
  createAnimationHorizontalPanelDelay(
    className: string,
    delay: number,
    delayImg: number,
    durationImg: number,
    simpleScaleIn: boolean = false
  ) {
    let fadePanelVision: ScrollAnimation = new ScrollAnimation(this.document);
    fadePanelVision.handleScroll(
      className,
      'fadeIn 1s ease-in-out forwards',
      delay,
      0
    );
    // Animation des images en position absolute
    let fadePanelVisionImg: ScrollAnimation = new ScrollAnimation(
      this.document
    );
    let animName = simpleScaleIn ? 'simpleScaleIn' : 'scaleIn';
    fadePanelVisionImg.handleScroll(
      className + 'Img',
      animName + ' ' + durationImg + 's ease-in-out forwards',
      delayImg,
      0
    );
  }

  redirectTo(path: string, attrs?: string, attrsKey?: string) {
    this.navigationService.navigateTo(path, attrs, attrsKey);
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  openUrlSelf(url: string) {
    window.open(url, '_self');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  getLangFullText(lang: string) {
    switch (lang) {
      case 'fr':
        return 'fr-FR';
      case 'fr-ca':
        return 'fr-CA';
      case 'en':
        return 'en-GB';
    }
    return 'en-GB';
  }

  getLangShortText(lang: string) {
    switch (lang) {
      case 'fr-FR':
        return 'fr';
      case 'fr-CA':
        return 'fr-ca';
      case 'en-GB':
        return 'en';
    }
    return 'en';
  }

  // ---------------- Méthode abstraites -----------------
  // Permet de modifier les métadonnées de la page
  abstract setHeaderMetadatas(): void;
  // Lance les différentes animations de la page
  abstract launchAnimations(): void;
}
