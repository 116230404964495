<div class="navbar-content">
  <!-- Background gradient -->
  <div class="navbar-gradient">
    <div class="gradient-circle green"></div>
    <div class="gradient-circle purple-one"></div>
    <div class="gradient-circle purple-two"></div>
    <div class="gradient-circle blue"></div>
    <div class="gradient-circle pink"></div>
  </div>

  <!-- Menu -->
  <div class="navbar-menu">
    <!-- Header -->
    <div class="navbar-menu-header">
      <img src="assets/images/logo_moffi_white.svg" alt="Moffi logo" />
      <mat-icon
        class="navbar-menu-header-icon dark-color"
        fontIcon="close"
        (click)="closeMenu()"
      ></mat-icon>
    </div>

    <!-- Menu liste-->
    <div class="navbar-menu-list">
      <!-- Solution -->
      <app-tree-menu-item
        class="navbar-menu-list-item"
        title="{{ 'header.menu.solutions.title' | translate }}"
        description="{{ 'header.menu.solutions.description' | translate }}"
        (onMenuOpen)="closeAllOtherMenu($event)"
      >
        <app-sub-menu-item
          (eventClick)="redirectTo('flex')"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'header.menu.solutions.flexOffice.title' | translate }}"
          description="{{
            'header.menu.solutions.flexOffice.description' | translate
          }}"
        ></app-sub-menu-item>
        <app-sub-menu-item
          (eventClick)="redirectTo('remote')"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'header.menu.solutions.homeOffice.title' | translate }}"
          description="{{
            'header.menu.solutions.homeOffice.description' | translate
          }}"
        ></app-sub-menu-item>
        <app-sub-menu-item
          (eventClick)="redirectTo('smart')"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'header.menu.solutions.smartOffice.title' | translate }}"
          description="{{
            'header.menu.solutions.smartOffice.description' | translate
          }}"
        ></app-sub-menu-item>
        <app-sub-menu-item
          (eventClick)="redirectTo('waldo')"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'header.menu.solutions.waldo.title' | translate }}"
          description="{{
            'header.menu.solutions.waldo.description' | translate
          }}"
        ></app-sub-menu-item>
      </app-tree-menu-item>

      <!-- Marketplace -->
      <app-tree-menu-item
        class="navbar-menu-list-item"
        title="{{ 'header.menu.marketplace.title' | translate }}"
        (click)="redirectTo('marketplace')"
        (onMenuOpen)="closeAllOtherMenu($event)"
      ></app-tree-menu-item>

      <!-- Offres -->
      <app-tree-menu-item
        class="navbar-menu-list-item"
        title="{{ 'header.menu.offers.title' | translate }}"
        (click)="redirectTo('pricing')"
        (onMenuOpen)="closeAllOtherMenu($event)"
      ></app-tree-menu-item>

      <!-- Langues -->
      <app-tree-menu-item
        class="navbar-menu-list-item"
        title="{{ currentLanguageShort }}"
        (onMenuOpen)="closeAllOtherMenu($event)"
      >
        <app-sub-menu-item
          icon="assets/images/icon/flag_fr.svg"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleTitleSelected="fw-550 fs-14 primary-color"
          [isSelected]="currentLanguageShort == 'FR'"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'languages.frFR.longName' | translate }}"
          [withArrow]="false"
          (eventClick)="changeLanguage('fr-FR')"
        ></app-sub-menu-item>
        <app-sub-menu-item
          icon="assets/images/icon/flag_ca.svg"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleTitleSelected="fw-550 fs-14 primary-color"
          [isSelected]="currentLanguageShort == 'CA'"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'languages.frCA.longName' | translate }}"
          [withArrow]="false"
          (eventClick)="changeLanguage('fr-CA')"
        ></app-sub-menu-item>
        <app-sub-menu-item
          icon="assets/images/icon/flag_en.svg"
          fontStyleTitle="fw-550 fs-14 dark-color"
          fontStyleTitleSelected="fw-550 fs-14 primary-color"
          [isSelected]="currentLanguageShort == 'GB'"
          fontStyleDesc="fw-400 fs-14 dark-color"
          title="{{ 'languages.enGB.longName' | translate }}"
          [withArrow]="false"
          (eventClick)="changeLanguage('en-GB')"
        ></app-sub-menu-item>
      </app-tree-menu-item>

      <!-- Aides -->
      <!-- <app-tree-menu-item class="navbar-menu-list-item" title="Aides" (onMenuOpen)="closeAllOtherMenu($event)">
                <app-sub-menu-item (eventClick)="callOpenAxeptioCookies()" fontStyleTitle="fw-550 fs-14 dark-color" fontStyleDesc="fw-400 fs-14 dark-color" title="{{'help.cookies.text' | translate}}"></app-sub-menu-item>
            </app-tree-menu-item> -->
    </div>

    <!-- Footer -->
    <div class="navbar-menu-footer">
      <!-- Bouton Connexion -->
      <moffi-ui-button
        (eventClick)="openLogin()"
        label="{{ 'header.actions.goToApp.text' | translate }}"
        class="navbar-menu-footer-item"
      ></moffi-ui-button>
      <!-- Bouton Réserver démo -->
      <moffi-ui-button
        (eventClick)="redirectTo('contact')"
        buttonType="light"
        label="{{ 'header.actions.demo.text' | translate }}"
        class="navbar-menu-footer-item"
      ></moffi-ui-button>
    </div>
  </div>
</div>
